export const String = {
  BRAND_NAME: 'EVOLVE',
  HEADER: {
    TAG_NAME: '#BetterYou',
  },
  DASHBOARD: {
    TITLE: [
      'Time to mark your Today’s challenge.',
      'Did you DO today’s part of the challenge?',
      'Is it a YES day?',
      "Did you succeed in today's Challenge?",
      'Did YOU run the day Today or Did the DAY run you?',
    ],
    COUNT_DOWN_CAPTION: 'Get ready for the challenge in',
  },
  CHALLENGE_FORM: {
    INPUT_PHOTO: 'Upload Today’s Photo',
    UPLOADING: 'Uploading...',
    INPUT_DESCRIPTION: [
      'How do you feel?',
      'Do you feel energized?',
      'Wanna share today’s experience?',
    ],
    SUBMIT: 'Done',
    CONFIRM_DIALOG: {
      TITLE: 'Confirm challenges',
      DESCRIPTION:
        'Please confirm the selected challenges before proceeding. There is no going back.',
      CTA_LABEL: 'Proceed',
      SELECTED: 'You have selected:',
    },
  },
  CHALLENGE_SELECTION: {
    CHALLENGE_FORM_SELECT_CHALLENGE:
      'The best thing you could ever do is BELIEVE in yourself. STOP wishing: START doing',
    SUBMIT: 'Get Started',
    AGREEMENT: 'I accept the challenge',
    GET_STARTED: "Let's Get Started",
  },
  MOTIVATIONAL_QUOTE: [
    'Big journeys start with small steps',
    '“Consistency is more important than Perfection”',
    '“Never underestimate the power of small steps”',
    '“Either you RUN the day or the DAY runs you”',
    '“Remember, Small shaky steps still count. Progress is Progress”',
    '“Consistency is what Transforms Average into Excellence”',
    '“Little by little, a little becomes  A LOT”',
    '“Passion + Consistency = SUCCESS”',
    '“DON’T stop until you are PROUD”',
    '“Success doesn’t come from what you do occasionally, but what you do consistently”',
    '“You don’t have to be extreme. Just consistent”',
    '“Remember! Consistency is the key”',
    '“Without Self-Discipline, success is impossible. Period.”',
    '“Discipline is the strongest form of Self Love”',
    '“Discipline is remembering what you want”',
    '“What you do TODAY can improve all your TOMORROWS”',
    '“Strive for progress not perfection”',
    '“You only fail when you stop TRYING.”',
    '“All progress takes place OUTSIDE your comfort zone”',
    '“When you start feeling the pain, that’s when everything starts!”',
    '“It only takes one person to change your life: YOU”',
    '“Practice insane.. Or remain the same”',
    '“You can have results or Excuses. NOT BOTH”',
    '“If you are PERSISTENT you will get it. If you are CONSISTENT you will keep it”',
  ],
  IMAGE_GALLERY_HEADER: 'Check out the photos',
};
