import React from 'react';
import { useLottie } from 'lottie-react';

type Props = {
  animationData: unknown;
  loop?: boolean;
  speed?: number;
};

export const LottiePlayer = (props: Props) => {
  const options = {
    animationData: props.animationData,
    loop: props.loop,
  };

  const Lottie = useLottie(options);

  if (props.speed) Lottie.setSpeed(props.speed);

  const { View } = Lottie;

  return <>{View}</>;
};
