import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import App from './App';
import ProtectedRoutes from './guards/ProtectedRoutes';
import ProtectLeaderBoardRoute from './guards/ProtectLeaderBoardRoute';
import { ChallengeLeaderboard } from './pages/ChallengeLeaderboard';
import { MasterLeaderboard } from './pages/MasterLeaderboard';
import { UserContext } from './providers/UserProvider';

export const AppRoutes = () => {
  const { user } = useContext(UserContext);
  const { isLoggedIn } = user;

  return (
    <Routes>
      <Route path="/" element={<App />} />

      <Route path="/" element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="/" element={<ProtectLeaderBoardRoute />}>
          <Route path="/leaderboard" element={<MasterLeaderboard />} />
          <Route path="/leaderboard/:id" element={<ChallengeLeaderboard />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
