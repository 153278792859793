import { useEffect, useState } from 'react';
import { Leaderboard } from '../component/leaderboard/Leaderboard';
import { LeaderboardData } from '../models/LeaderboardData';
import { fetchMasterLeaderboardData } from '../services/apis';

export const MasterLeaderboard = () => {
  const [leaderBoardData, setLeaderboardData] = useState<Array<LeaderboardData>>([]);

  useEffect(() => {
    const fetchLeaderBoard = async () => {
      const data = await fetchMasterLeaderboardData();

      setLeaderboardData(data);
    };

    fetchLeaderBoard();
  }, []);

  return <Leaderboard heading="Leaderboard" records={leaderBoardData} showChallenge />;
};
