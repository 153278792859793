import { useContext, useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Link, useNavigate } from 'react-router-dom';
import { ChallengeCategory, TChallengeCategory } from '../../constant/data';
import { ChallengesRegistered } from '../../models/ChallengesRegistered';
import { UserContext } from '../../providers/UserProvider';
import { fetchChallengesByUser } from '../../services/apis';
import { String } from '../../constant/string';
import {
  showLeaderBoardMenu,
  checkIsPreviousDate,
  getRandomIndex,
  checkEventStatus,
} from '../../util/common';
import { Button } from '../base/button/Button';
import { CheckInModal } from '../dashboard/check-in-modal/CheckInModal';
import { PhotoGallery } from '../photoGallery/PhotoGallery';
import { Icon, ICON_COLOR } from '../icon/Icon';
import './CheckInRecord.scss';
import { EVENT_STATUS } from '../../constant/config';

interface Props {
  dayIndex: number;
}

export const CheckInRecord = ({ dayIndex }: Props) => {
  const quoteIndex = getRandomIndex(String.MOTIVATIONAL_QUOTE.length, 24);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [selectedChallenges, setSelectedChallenges] = useState<Array<ChallengesRegistered>>([]);

  const [openModal, setOpenModal] = useState(false);
  const [showExplosion, setShowExplosion] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState<TChallengeCategory>(
    ChallengeCategory[0]
  );
  const [registeredChallengeId, setRegisteredChallengeId] = useState('');
  const eventStatus = checkEventStatus();

  useEffect(() => {
    const fetchChallenges = async () => {
      const challenges = await fetchChallengesByUser(user.id);
      setSelectedChallenges(challenges);
    };
    fetchChallenges();
  }, [user.id, openModal]);

  useEffect(() => {
    document.querySelector('.ChallengeCalendar-item.active')?.scrollIntoView({ inline: 'center' });
  }, []);

  const toggleCheckInModal = (challenge: ChallengesRegistered, status: boolean) => {
    setRegisteredChallengeId(challenge.registeredChallengeId);
    setSelectedChallenge(challenge.challenge);
    setOpenModal(status);
  };

  const checkInModalCallback = (isModalOpen: boolean, isDataSubmit = false) => {
    setOpenModal(isModalOpen);
    if (isDataSubmit && !showExplosion) {
      setTimeout(() => setShowExplosion(true), 300);
      setTimeout(() => {
        setShowExplosion(false);
      }, 3000);
    }
  };
  return (
    <>
      {showExplosion && <ConfettiExplosion />}
      <div className="CheckInRecord">
        <ul className="CheckInRecord-items">
          {selectedChallenges.map((data, index) => (
            <li key={index} className="CheckInRecord-item">
              <div className={`CheckInRecord-item-wrapper checkIn-icon ${data.challenge.icon}`} />
              <div className="CheckInRecord-item-wrapper checkIn-content">
                {showLeaderBoardMenu() ? (
                  <Link className="link" to={`/leaderboard/${data.challenge.id}`}>
                    {data.challenge?.name}
                  </Link>
                ) : (
                  <span>{data.challenge?.name}</span>
                )}
                {data.checkInArray[dayIndex] && data.checkInArray[dayIndex].duration !== '0' && (
                  <p className="checkIn-qty">
                    <span>{data.checkInArray[dayIndex].duration}</span>
                  </p>
                )}
                {data.checkInArray[dayIndex] && data.checkInArray[dayIndex].quantity !== '0' && (
                  <p className="checkIn-qty">
                    <span>{data.checkInArray[dayIndex].quantity}</span>
                  </p>
                )}
              </div>
              <button
                className="CheckInRecord-item-wrapper checkIn-status"
                disabled={
                  !!data.checkInArray[dayIndex] ||
                  checkIsPreviousDate(dayIndex + 1) ||
                  eventStatus === EVENT_STATUS.COMPLETED
                }
                onClick={() => toggleCheckInModal(data, true)}>
                {!data.checkInArray[dayIndex] ? (
                  <Icon name="add" color={ICON_COLOR.primary} />
                ) : (
                  <Icon name="check" color={ICON_COLOR.success} />
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <CheckInModal
        challenge={selectedChallenge}
        dayIndex={dayIndex}
        openModal={openModal}
        registeredChallengeId={registeredChallengeId}
        toggleModalState={checkInModalCallback}
      />

      <PhotoGallery dayIndex={dayIndex} selectedChallenges={selectedChallenges} />
      <p className="motivational_quote">{String.MOTIVATIONAL_QUOTE[quoteIndex]}</p>
      {showLeaderBoardMenu() && (
        <div className="leaderboard-cta">
          <Button label="LeaderBoard" onClickHandler={() => navigate('/leaderboard')}></Button>
        </div>
      )}
    </>
  );
};
