import { useContext } from 'react';
import './App.scss';
import Login from './component/auth/Login';
import { Loader } from './component/loader/loader';
import RegistrationsClosed from './component/registrationClosed/registrationClosed';
import Challenges from './pages/Challenges';
import { Dashboard } from './pages/dashboard/Dashboard';
import { UserContext } from './providers/UserProvider';

function App() {
  const { user, isPending } = useContext(UserContext);
  const { isLoggedIn, challenges, isRegistered } = user;

  return (
    <>
      {isPending ? (
        <Loader />
      ) : !isLoggedIn ? (
        <Login />
      ) : !isRegistered ? (
        <RegistrationsClosed />
      ) : challenges?.length > 0 ? (
        <Dashboard />
      ) : (
        <Challenges />
      )}
    </>
  );
}

export default App;
