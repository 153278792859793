export const LeaderBoardRankLimit = 10;
export const START_DATE = '1 feb 2023';
export const END_DATE = '21 feb 2023';
export const DAYS_TO_DISPLAY_LEADER_BOARD = 21;

export const enum EVENT_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  UNKNOWN = 'UNKNOWN',
}
