import { useContext, useEffect, useState } from 'react';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import useAuth from '../../hooks/useAuth';
import { UserContext } from '../../providers/UserProvider';
import './Login.scss';
import { Loader } from '../loader/loader';
import { LottiePlayer } from '../lottie/LottiePlayer';
import yogaAnimation from '../../assets/lottie/yoga.json';
import waterAnimation from '../../assets/lottie/water.json';
import dietAnimation from '../../assets/lottie/diet.json';
import cyclingAnimation from '../../assets/lottie/cycling.json';
import { String } from '../../constant/string';

const transitionDelay = 3000;
const animationItemsCount = 4;

const Login = () => {
  const { signInWithGoogle, isLoading } = useAuth();
  const [heroAnimationIndex, setHeroAnimationIndex] = useState(0);
  const { isYMLUser } = useContext(UserContext);

  useEffect(() => {
    const timeIntervalRef = setInterval(() => {
      setHeroAnimationIndex((prevState) => (prevState + 1) % animationItemsCount);
    }, transitionDelay);

    return () => {
      if (timeIntervalRef) clearInterval(timeIntervalRef);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="login min-h-screen overflow-auto p-8 pt-0 break-words">
      <div className="logo-container">
        <div className="logo" />
      </div>

      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className={`img-container item-${heroAnimationIndex}`}
          key={heroAnimationIndex}
        >
          {heroAnimationIndex === 0 && (
            <LazyMotion features={domAnimation}>
              <m.span>
                <LottiePlayer animationData={yogaAnimation}></LottiePlayer>
              </m.span>
            </LazyMotion>
          )}
          {heroAnimationIndex === 1 && (
            <LazyMotion features={domAnimation}>
              <m.span>
                <LottiePlayer animationData={waterAnimation}></LottiePlayer>
              </m.span>
            </LazyMotion>
          )}
          {heroAnimationIndex === 2 && (
            <LazyMotion features={domAnimation}>
              <m.span transition={{ delay: 1 }}>
                <LottiePlayer animationData={dietAnimation}></LottiePlayer>
              </m.span>
            </LazyMotion>
          )}
          {heroAnimationIndex === 3 && (
            <LazyMotion features={domAnimation}>
              <m.span>
                <LottiePlayer animationData={cyclingAnimation}></LottiePlayer>
              </m.span>
            </LazyMotion>
          )}
        </m.div>
      </LazyMotion>
      <div className="content">
        <p className={`content-heading text-${heroAnimationIndex}`}>
          {isYMLUser ? (
            <>
              <span>Conquer your habits,</span> <span>Crush your goals.</span>
            </>
          ) : (
            'Please Login with YML account!'
          )}
        </p>
        <p className="content-description">
          {isYMLUser ? (
            <>
              We become what we repeatedly do. The <strong>{String.BRAND_NAME}</strong> app will help you to make better habit and
              break bad ones. It is based on the <q>Don&apos;t break the Chain</q> Philosophy
            </>
          ) : null}
        </p>
      </div>
      <div className="login-btn-wrapper">
        <LazyMotion features={domAnimation}>
          <m.button
            className="loginBtn w-full m-auto shadow-[5px_5px_10px_0_rgba(63,63,63,0.86)] text-1xl"
            onClick={signInWithGoogle}
            animate={{ scale: 1 }}
            whileTap={{ scale: 1.1 }}
          >
            {isYMLUser ? 'Sign in with Google' : 'Try Again'}
          </m.button>
        </LazyMotion>
      </div>
    </div>
  );
};

export default Login;
