import { useState, useEffect, useContext } from 'react';
import { LeaderboardData } from '../../models/LeaderboardData';
import { DashboardHeader } from '../dashboard/dashboard-header/DashboardHeader';
import './Leaderboard.scss';
import ConfettiExplosion from 'react-confetti-explosion';
import { UserContext } from '../../providers/UserProvider';
import { Link } from 'react-router-dom';
import { LeaderBoardRankLimit } from '../../constant/config';
// import { ACTIVITY_LIFESPAN_IN_DAYS } from '../../util/common';

interface Props {
  heading: string;
  records: Array<LeaderboardData>;
  showChallenge?: boolean;
  type?: string;
}

export const Leaderboard = ({ heading, records, showChallenge = false, type }: Props) => {
  const [isExploding, setIsExploding] = useState(false);
  const { user } = useContext(UserContext);
  const { name } = user;

  const showConfetti = () => {
    setTimeout(() => {
      setIsExploding(true);
    }, 500);
    setTimeout(() => {
      setIsExploding(false);
    }, 4000);
  };

  useEffect(() => showConfetti(), [records]);

  useEffect(() => showConfetti(), [records]);

  const TopRankRecords = records.filter((record) => record.rank <= LeaderBoardRankLimit);

  return (
    <>
      {isExploding && <ConfettiExplosion force={1} />}
      <div className="Leaderboard">
        <DashboardHeader menuList={[{ icon: 'dashboard', name: 'Dashboard', href: '/' }]} />
        <h1 className="Leaderboard-heading">
          🎖 {heading} - Top {LeaderBoardRankLimit}
          <span className="Leaderboard-type">{type}</span>
        </h1>

        <ul className="Leaderboard-items">
          {TopRankRecords.map((data, index) => (
            <li
              className={`Leaderboard-item ${!showChallenge && 'Leaderboard-item--noChallenge'} ${
                name === data.name ? 'active-user' : ''
              }`}
              key={index}>
              <img alt="" className="profile" src={data.profileImage} />
              <div className="content-wrapper">
                <div className="content">
                  <span className="username">{data.name}</span>
                  {showChallenge && (
                    <span className="challenge">
                      <Link className="link challenge-name dark" to={`/leaderboard/${data.id}`}>
                        {data.challengeName}
                      </Link>
                    </span>
                  )}
                </div>
                {/* commenting to hide the progress on leaderboard. */}
                {/* <div className="progress">
                  {data.daysCompleted > ACTIVITY_LIFESPAN_IN_DAYS
                    ? ACTIVITY_LIFESPAN_IN_DAYS
                    : data.daysCompleted}
                  /{ACTIVITY_LIFESPAN_IN_DAYS} days
                </div> */}
                <div className="rank">#{data.rank}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
