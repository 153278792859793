import { Icon, ICON_COLOR } from '../icon/Icon';
import './Checkbox.scss';
import React from 'react';
import { domAnimation, LazyMotion, m } from 'framer-motion';

type Props = {
  label: string;
  value?: boolean;
  id: string;
  bgType?: 'light' | 'dark';
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

export const Checkbox = (props: Props) => {
  return (
    <label htmlFor={props.id} className={`Checkbox bg-${props.bgType}`}>
      <LazyMotion features={domAnimation}>
        <m.div whileTap={{ scale: 1.1 }} className="Checkbox-container">
          <input
            defaultChecked={props.value}
            type="checkbox"
            id={props.id}
            name={props.id}
            className="Checkbox-input"
            onChange={props.onChangeHandler}
          />
          <span className="Checkbox-icon">
            <Icon
              name="check"
              color={props.bgType === 'light' ? ICON_COLOR.golden : ICON_COLOR.default}
            />
          </span>
        </m.div>
      </LazyMotion>
      <span className="Checkbox-label"> {props.label}</span>
    </label>
  );
};
