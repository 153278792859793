import { useState } from 'react';
import { ChallengeCalendar } from '../../component/challengeCalendar/ChallengeCalendar';
import { CheckInRecord } from '../../component/CheckInRecord/CheckInRecord';
import { DashboardHeader } from '../../component/dashboard/dashboard-header/DashboardHeader';
import './Dashboard.scss';
import { String } from '../../constant/string';
import { START_DATE, EVENT_STATUS } from '../../constant/config';
import {
  getRandomIndex,
  getDayDiff,
  checkEventStatus,
  showLeaderBoardMenu,
} from '../../util/common';
import { CountDown } from '../../component/CountDown/CountDown';

export const Dashboard = () => {
  const currentDayIndex = getDayDiff(START_DATE, new Date().toString());
  const [dayIndex, setDayIndex] = useState(currentDayIndex);
  const titleIndex = getRandomIndex(String.DASHBOARD.TITLE.length);
  const eventStatus = checkEventStatus();

  return (
    <div className="Dashboard">
      <DashboardHeader
        menuList={
          showLeaderBoardMenu()
            ? [{ icon: 'leader-board', name: 'Leader board', href: '/leaderboard' }]
            : []
        }
      />
      {(eventStatus === EVENT_STATUS.STARTED || eventStatus === EVENT_STATUS.COMPLETED) && (
        <>
          <ChallengeCalendar callback={setDayIndex} />
          <h1 className="Dashboard-title px-2">{String.DASHBOARD.TITLE[titleIndex]}</h1>
          <CheckInRecord dayIndex={dayIndex} />
        </>
      )}
      {eventStatus === EVENT_STATUS.NOT_STARTED && (
        <div className="CountDown-wrapper">
          <p className="countdown-text">{String.DASHBOARD.COUNT_DOWN_CAPTION}</p>
          <CountDown></CountDown>
        </div>
      )}
    </div>
  );
};
