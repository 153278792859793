import { useState } from 'react';
import './ChallengeSelection.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { ChallengeCategory, TChallengeCategory } from '../../constant/data';
// import required modules
import { EffectCards } from 'swiper';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { LottiePlayer } from '../lottie/LottiePlayer';
import checkAnimation from '../../assets/lottie/check.json';

type Props = {
  callback: (x: Array<TChallengeCategory>) => void;
};

export const ChallengeSelection = (props: Props) => {
  const [selectedChallenges, setSelectedChallenges] = useState<Array<TChallengeCategory>>([]);

  const findChallengeItemIndex = (challengeId: string) => {
    return selectedChallenges.findIndex((challenge) => challenge.id === challengeId);
  };

  const removeSelectedChallenge = (index: number) => {
    const tempSelectedChallenges = [...selectedChallenges];
    tempSelectedChallenges.splice(index, 1);
    setSelectedChallenges(tempSelectedChallenges);
    props.callback(tempSelectedChallenges);
  };

  const challengeSelectHandler = (challenge: TChallengeCategory) => {
    const challenges = [...selectedChallenges, challenge];
    props.callback(challenges);
    setSelectedChallenges(challenges);
  };

  return (
    <div className="ChallengeSelection">
      <Swiper effect={'cards'} grabCursor={true} modules={[EffectCards]} className="mySwiper">
        {ChallengeCategory.map((challenge) => (
          <SwiperSlide key={challenge.id}>
            <span className="challenge-label">{challenge.name}</span>
            <img alt="running" src={challenge.img} />
            {findChallengeItemIndex(challenge.id) !== -1 ? (
              <LazyMotion features={domAnimation}>
                <m.button
                  whileTap={{ scale: 1.1 }}
                  className="ChallengeSelection-btn"
                  onClick={() => removeSelectedChallenge(findChallengeItemIndex(challenge.id))}
                >
                  <LottiePlayer animationData={checkAnimation} loop={false} speed={5} />
                </m.button>
              </LazyMotion>
            ) : (
              selectedChallenges.length < 3 && (
                <LazyMotion features={domAnimation}>
                  <m.button
                    whileTap={{ scale: 1.1 }}
                    className="ChallengeSelection-btn"
                    onClick={() => challengeSelectHandler(challenge)}
                  >
                    Select
                  </m.button>
                </LazyMotion>
              )
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
