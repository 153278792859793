import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Leaderboard } from '../component/leaderboard/Leaderboard';
import { ChallengeCategory } from '../constant/data';
import { LeaderboardData } from '../models/LeaderboardData';
import { fetchChallengeLeaderboardData } from '../services/apis';

export const ChallengeLeaderboard = () => {
  const [leaderBoardData, setLeaderboardData] = useState<Array<LeaderboardData>>([]);
  const [challengeName, setChallengeName] = useState('');
  const params = useParams();

  useEffect(() => {
    const challengeId = params.id as string;

    const challengeName = ChallengeCategory.find((challenge) => challenge.id === challengeId)
      ?.name as string;

    setChallengeName(challengeName);

    const fetchLeaderBoard = async () => {
      const data = await fetchChallengeLeaderboardData(challengeId);

      setLeaderboardData(data);
    };

    fetchLeaderBoard();
  }, [params.id]);

  return <Leaderboard heading={'Leaderboard'} type={challengeName} records={leaderBoardData} />;
};
