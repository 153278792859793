import { Menu, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { UserContext } from '../../../providers/UserProvider';
import './DashboardHeader.scss';
import { String } from '../../../constant/string';
import { Icon } from '../../icon/Icon';
import { MenuItem } from '../../../types/MenuItem';

type TDashboardHeader = {
  menuList?: Array<MenuItem>;
};

export const DashboardHeader = ({ menuList = [] }: TDashboardHeader) => {
  const { user } = useContext(UserContext);
  const { name, picture } = user;

  const { logOut } = useAuth();

  return (
    <header className="DashboardHeader sticky top-0 z-10">
      <ul className="flex justify-between items-center h-full mx-4 header-items">
        <li className="flex items-center gap-x-2">
          <span className="profile-wrapper">
            <img
              alt="Profile"
              className="profile-img"
              src={
                picture
                  ? picture
                  : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png`
              }
            />
          </span>
          <div>
            <span className="username">{name}</span>
            <span className="tagName">{String.HEADER.TAG_NAME}</span>
          </div>
        </li>
        <li>
          <Menu as="div" className="relative ml-3 header-flex-items">
            <Link to="/" className="logo"></Link>
            <div>
              <Menu.Button className="flex max-w-xs items-center rounded-full text-sm focus:outline-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                  />
                </svg>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {menuList.map((nav) => (
                  <Menu.Item key={nav.name} as={Fragment}>
                    {({ active }) => (
                      <Link
                        to={nav.href}
                        className={`
                        ${
                          active && 'bg-gray-100'
                        } block px-4 py-2 text-sm text-gray-700 flex items-center
                      `}
                      >
                        <Icon name={nav.icon} />

                        <span className="ml-4">{nav.name}</span>
                      </Link>
                    )}
                  </Menu.Item>
                ))}
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`
                          ${
                            active && 'bg-gray-100'
                          } block px-4 py-2 text-sm text-gray-700 flex items-center w-full
                        `}
                      onClick={logOut}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                        />
                      </svg>

                      <span className="ml-4">Logout</span>
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </li>
      </ul>
    </header>
  );
};
