import './Button.scss';
import { m, LazyMotion, domAnimation } from 'framer-motion';

type TButton = {
  label: string;
  onClickHandler?: (e: React.MouseEvent<HTMLElement>) => void;
  variant?: 'PRIMARY' | 'SECONDARY';
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
};

export const Button = ({ label, type, variant, disabled, onClickHandler }: TButton) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        whileTap={{ scale: 1.1 }}
        initial={{scale:1}}
        animate={{}}
        type={type}
        className={`BaseButton btn font-bold ${variant === 'SECONDARY' ? 'secondary' : 'primary'}`}
        onClick={onClickHandler}
        disabled={disabled}
      >
        {label}
      </m.button>
    </LazyMotion>
  );
};
