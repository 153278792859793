import variables from '../../styles/variables.module.scss';

export const ICON_SIZE = {
  sm: '18px',
  md: '24px',
  lg: '48px',
  xl: '96px',
  default: '18px',
};

export const ICON_COLOR = {
  primary: variables['primary-color'],
  success: 'green',
  error: 'red',
  warning: 'orange',
  white: '#fff',
  golden: variables['golden-color'],
  goldenDisabled: variables['golden-disabled-color'],
  default: variables['primary-color'],
};

type Props = {
  color?: string;
  name: string;
  size?: string;
};

export const Icon = (props: Props) => {
  const size = props.size || ICON_SIZE.default;
  const color = props.color || ICON_COLOR.default;
  let icon;
  if (props.name === 'check') {
    icon = (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <path
          d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"
          fill={color}
        />
      </svg>
    );
  } else if (props.name === 'add') {
    icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        id="Layer_1"
        version="1.1"
        viewBox="0 0 32 32"
      >
        <path
          d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z"
          fill={color}
        />
      </svg>
    );
  } else if (props.name === 'close') {
    icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
          fill={color}
          stroke={color}
        />
      </svg>
    );
  } else if (props.name === 'submit-arrow') {
    icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        enableBackground="new 0 0 64 64"
        viewBox="0 0 64 64"
      >
        <g transform="translate(28 328)">
          <path
            fill={color}
            d="M4-272.1c-13.2 0-23.9-10.7-23.9-23.9S-9.2-319.9 4-319.9s23.9 10.7 23.9 23.9S17.2-272.1 4-272.1zm0-45.2c-11.7 0-21.3 9.6-21.3 21.3s9.6 21.3 21.3 21.3 21.3-9.6 21.3-21.3-9.6-21.3-21.3-21.3z"
          />
          <path fill={color} d="m3.5-282.3-1.8-1.9L13.4-296 1.7-307.8l1.8-1.9L17.2-296 3.5-282.3" />
          <path fill={color} d="M15.3-294.6h-24v-2.8h24z" />
        </g>
      </svg>
    );
  } else if (props.name === 'leader-board') {
    icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
        />
      </svg>
    );
  } else if (props.name === 'dashboard') {
    icon = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
        />
      </svg>
    );
  }
  return <>{icon}</>;
};
