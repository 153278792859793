import './ChallengeCalendar.scss';
import { useState } from 'react';
import { getDayDetails, getDayDiff, mapDay } from '../../util/common';
import { START_DATE, END_DATE } from '../../constant/config';

type Props = {
  callback: (index: number) => void;
};

export const ChallengeCalendar = (props: Props) => {
  const [activeDateIndex, setActiveDateIndex] = useState(
    getDayDiff(START_DATE, new Date().toDateString())
  );

  const dayDiff = getDayDiff(START_DATE, END_DATE);
  const ChallengeDayArr = getDayDetails(START_DATE, dayDiff + 1);

  const dateSelectHandler = (index: number) => {
    setActiveDateIndex(index);
    props.callback(index);
  };

  return (
    <div className="ChallengeCalendar-wrapper">
      <ul className="ChallengeCalendar">
        {ChallengeDayArr.map((day, index) => (
          <li
            key={index}
            className={`ChallengeCalendar-item ${activeDateIndex === index ? 'active' : ''} ${
              !day.isPresentDate && !day.isPreviousDate ? 'disabled' : ''
            }`}
            tabIndex={0}
            onClick={() => {
              if (!day.isPresentDate && !day.isPreviousDate) return;
              dateSelectHandler(index);
            }}>
            <>
              <span className="day">{mapDay(day.day)}</span>
              <span className="date">{day.date}</span>
            </>
          </li>
        ))}
      </ul>
    </div>
  );
};
