import { ChallengesRegistered } from '../models/ChallengesRegistered';

export const fetchCheckInIds = (
  challenges: Array<ChallengesRegistered>,
  currentDayIndex: number
) => {
  const checkInIds: string[] = [];
  challenges.forEach((challenge: ChallengesRegistered) => {
    if (challenge?.checkInArray[currentDayIndex]) {
      checkInIds.push(challenge?.checkInArray[currentDayIndex].id);
    }
  });
  return checkInIds;
};

export const getLocaleDateString = (timeStampSeconds: number) =>
  new Date(timeStampSeconds * 1000).toLocaleDateString();

export const getDateString = (timeStampSeconds: number) =>
  new Date(timeStampSeconds * 1000).toDateString();
