export const ACTIVITY_LIFESPAN_IN_DAYS = 21;
import {
  START_DATE,
  END_DATE,
  EVENT_STATUS,
  DAYS_TO_DISPLAY_LEADER_BOARD,
} from '../constant/config';

const aDayInMilliseconds = 1000 * 60 * 60 * 24;

export const getDayDetails = (startDate: string, daysCount: number) => {
  const startDay = new Date(startDate);
  const presentDayTime = new Date().setHours(0, 0, 0, 0);

  const challengeDays = [];

  let currentDay = startDay;

  for (let i = 0; i < daysCount; i++) {
    challengeDays.push({
      date: currentDay.getDate(),
      day: currentDay.getDay(),
      month: currentDay.getMonth(),
      isPresentDate: currentDay.getTime() === presentDayTime ? true : false,
      isPreviousDate: currentDay.getTime() < presentDayTime ? true : false,

    });
    currentDay = new Date(startDay.setDate(startDay.getDate() + 1));
  }

  return challengeDays;
};

export const getDayDiff = (startDate: string, endDate: string) =>
  Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / aDayInMilliseconds);

const Days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const mapDay = (index: number) => Days[index];

const Months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
export const mapMonth = (index: number) => Months[index];

export const getRandomIndex = (lengthLimit: number, maxDigit = 10) =>
  Math.ceil((Math.random() * maxDigit) % lengthLimit) - 1;

export const checkEventStatus = () => {
  const currentDayTime = new Date().setHours(0, 0, 0, 0);
  const eventStartDayTime = new Date(START_DATE).getTime();
  const eventEndDayTime = new Date(END_DATE).getTime();

  if (currentDayTime < eventStartDayTime) {
    return EVENT_STATUS.NOT_STARTED;
  }
  if (currentDayTime <= eventEndDayTime) {
    return EVENT_STATUS.STARTED;
  }
  if (currentDayTime > eventEndDayTime) {
    return EVENT_STATUS.COMPLETED;
  }

  return EVENT_STATUS.UNKNOWN;
};

export const showLeaderBoardMenu = () => {
  if (checkEventStatus() === EVENT_STATUS.NOT_STARTED) {
    return false;
  }
  if (checkEventStatus() === EVENT_STATUS.COMPLETED) {
    return true;
  }
  const dayDiff = getDayDiff(START_DATE, new Date().toDateString());
  return dayDiff >= DAYS_TO_DISPLAY_LEADER_BOARD;
};

export const checkIsPreviousDate = (day: number) => {
  const presentDayTimeStamp = new Date().setHours(0, 0, 0, 0);
  const eventStartDate = new Date(START_DATE);
  const eventDay = new Date(eventStartDate.setDate(eventStartDate.getDate() + day)).setHours(0, 0, 0);
  return (eventDay <= presentDayTimeStamp);
}
