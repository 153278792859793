export type TChallengeCategory = {
  activityName?: string;
  icon: string;
  id: string;
  img: string;
  inputLabel?: string;
  inputType?: string;
  name: string;
  qtyInfo?: string;
  shortName: string;
};

export const ChallengeCategory: TChallengeCategory[] = [
  {
    activityName: 'hydrate',
    icon: 'drink_water',
    id: '11',
    img: 'https://images.pexels.com/photos/6932525/pexels-photo-6932525.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Glasses',
    inputType: 'quantity',
    name: 'Hydrate',
    qtyInfo: '100ML',
    shortName: 'Hydrate',
  },
  {
    activityName: 'stretch',
    icon: 'stretch',
    id: '15',
    img: 'https://images.pexels.com/photos/6707083/pexels-photo-6707083.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Stretch',
    shortName: 'Stretch',
  },
  {
    activityName: 'reading',
    icon: 'reading',
    id: '5',
    img: 'https://images.pexels.com/photos/3007370/pexels-photo-3007370.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Start Reading',
    shortName: 'Reading',
  },
  {
    activityName: 'sleep-routine',
    icon: 'wake_up_early',
    id: '12',
    img: 'https://images.pexels.com/photos/7622520/pexels-photo-7622520.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Stick to a Sleep routine',
    shortName: 'Sleep Routine',
  },
  {
    activityName: 'diet',
    icon: 'diet',
    id: '9',
    img: 'https://images.pexels.com/photos/5966431/pexels-photo-5966431.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Start a Diet',
    shortName: 'Diet',
  },
  {
    activityName: 'kick-an-unwanted-habit',
    icon: 'no_habit',
    id: '10',
    img: 'https://images.pexels.com/photos/2077996/pexels-photo-2077996.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Kick an unwanted Habit',
    shortName: 'Kick an Unwanted Habit',
  },
  {
    activityName: 'walking-running',
    icon: 'running',
    id: '1',
    img: 'https://images.pexels.com/photos/4719948/pexels-photo-4719948.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Shoes ON: Walking/Running',
    shortName: 'Walking/Running',
  },
  {
    activityName: 'cycling',
    icon: 'cycling',
    id: '2',
    img: 'https://images.pexels.com/photos/5807530/pexels-photo-5807530.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Go out Cycling',
    shortName: 'Cycling',
  },
  {
    activityName: 'gym-workout',
    icon: 'gym',
    id: '3',
    img: 'https://images.pexels.com/photos/4720255/pexels-photo-4720255.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Hit the Gym/Workout',
    shortName: 'Gym/Workout',
  },
  {
    activityName: 'meditate',
    icon: 'yoga',
    id: '6',
    img: 'https://images.pexels.com/photos/2597205/pexels-photo-2597205.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Keep calm & Meditate',
    shortName: 'Meditate',
  },
  {
    activityName: 'sunbath',
    icon: 'sunbath',
    id: '14',
    img: 'https://images.pexels.com/photos/4112894/pexels-photo-4112894.jpeg?auto=compress&cs=tinysrgb&w=1600',
    name: 'Take Sunbath',
    shortName: 'Sunbath',
  },
  {
    activityName: 'new-sport',
    icon: 'sports',
    id: '4',
    img: 'https://images.pexels.com/photos/1152853/pexels-photo-1152853.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Play new sport',
    shortName: 'New Sport',
  },
  {
    activityName: 'music-instrument',
    icon: 'instrument',
    id: '7',
    img: 'https://images.pexels.com/photos/8107171/pexels-photo-8107171.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Play a musical instrument',
    shortName: 'Music Instrument',
  },
  {
    activityName: 'art-craft',
    icon: 'art',
    id: '8',
    img: 'https://images.pexels.com/photos/8612995/pexels-photo-8612995.jpeg?auto=compress&cs=tinysrgb&w=1600',
    inputLabel: 'Time spent (Mins)',
    inputType: 'time',
    name: 'Create an Art/Craft',
    shortName: 'Art/Craft',
  },
];
