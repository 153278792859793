import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { TChallengeCategory } from '../../../constant/data';
import { String } from '../../../constant/string';
import { storage } from '../../../firebase/Config';
import { CheckIn } from '../../../models/CheckIn';
import { UserContext } from '../../../providers/UserProvider';
import { addDailyCheckIn } from '../../../services/apis';
import { getRandomIndex } from '../../../util/common';
import { Button } from '../../base/button/Button';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Icon, ICON_COLOR } from '../../icon/Icon';
import './CheckInModal.scss';

let descriptionIndex: number;
interface ModalProps {
  challenge: TChallengeCategory;
  dayIndex: number;
  openModal: boolean;
  registeredChallengeId: string;
  toggleModalState: (state: boolean, isDataSubmit?: boolean) => void;
}

interface FormProps {
  description: string;
  image: string;
  quantity: number;
  time: number;
  imageName: null | string;
}

export const CheckInModal = ({
  dayIndex,
  challenge,
  openModal,
  registeredChallengeId,
  toggleModalState,
}: ModalProps) => {
  const { user } = useContext(UserContext);

  const currentDate = new Date();
  const currentFolder = `${currentDate.getDate()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getFullYear()}`;

  const initialFormState = {
    description: '',
    image: '',
    quantity: 0,
    time: 0,
    imageName: null,
  };

  const [formState, setFormState] = useState<FormProps>(initialFormState);
  const [isTaskDone, setIsTaskDone] = useState(false);
  const [isFileSizeExceeding, setIsFileSizeExceeding] = useState(false);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const showCheckGif = false;

  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const inputType = challenge.inputType;
  const inputLabel = challenge.inputLabel;
  const qtyInfo = challenge.qtyInfo;

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFileUploading(true);
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];
      // Max File size 5MB
      if (image.size > 5242880) {
        setIsFileSizeExceeding(true);
        return;
      }

      setIsFileSizeExceeding(false);
      uploadToFirebaseStorage(image);
    }
  };

  const uploadToFirebaseStorage = async (image: File) => {
    const imageRef = ref(
      storage,
      `${currentFolder}/${user.name}-${challenge.activityName}-${image.name}`
    );
    let uploadRef: unknown;
    try {
      uploadRef = await uploadBytes(imageRef, image);
    } catch (error) {
      console.error('file upload', error);
    }

    if (uploadRef) {
      try {
        const imageUrl = await getDownloadURL(
          ref(storage, `${currentFolder}/${user.name}-${challenge.activityName}-${image.name}`)
        );

        setFormState({
          ...formState,
          image: imageUrl,
          imageName: image.name,
        });
      } catch (error) {
        console.error(error);
      }
    }

    setIsFileUploading(false);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const checkInData: CheckIn = {
      description: formState.description,
      duration: formState.time,
      imageUrl: formState.image,
      isTaskCompleted: isTaskDone,
      quantity: formState.quantity,
    };

    await addDailyCheckIn(checkInData, dayIndex, registeredChallengeId);

    setFormState(initialFormState);
    setIsTaskDone(false);
    toggleModalState(false, true);
    setIsTaskDone(false);
  };

  const onCancel = () => {
    setFormState(initialFormState);
    setIsTaskDone(false);
    toggleModalState(false);
  };

  useEffect(() => {
    descriptionIndex = getRandomIndex(String.CHALLENGE_FORM.INPUT_DESCRIPTION.length);
  }, []);

  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={toggleModalState}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className=" modal-container relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <form
                    onSubmit={onSubmit}
                    className={`challenge-record-form ${isTaskDone ? 'completed' : ''}`}>
                    <div className="mt-3 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className=" challenge-name text-lg leading-6 text-gray-900 text-center font-bold">
                        {challenge.name}
                        <button
                          className="absolute close-btn"
                          onClick={onCancel}
                          ref={cancelButtonRef}
                          type="button">
                          <Icon name="close" color={ICON_COLOR.primary} />
                        </button>
                      </Dialog.Title>

                      <div className="flex flex-col my-4 mt-2">
                        {inputType && (
                          <div className="input-control">
                            <label htmlFor={inputType}>
                              {' '}
                              {inputLabel}{' '}
                              <span className="qty-info">{qtyInfo && `(${qtyInfo})`}</span>
                            </label>
                            <input
                              className="h-8 block rounded-md border-2 border-gray-400 px-3 focus:outline-none focus:border-blue-900 focus:ring-blue-900"
                              id={inputType}
                              name={inputType}
                              type="number"
                              placeholder="5"
                              onChange={(e) =>
                                setFormState({
                                  ...formState,
                                  [inputType]: +e.target.value,
                                })
                              }
                            />
                          </div>
                        )}

                        <div className="flex flex-col mt-2 input-control">
                          <label htmlFor="description">
                            {' '}
                            {String.CHALLENGE_FORM.INPUT_DESCRIPTION[descriptionIndex]}
                          </label>
                          <textarea
                            className="block rounded-md border-2 border-gray-400 px-3 focus:outline-none focus:border-blue-900 focus:ring-blue-900"
                            id="description"
                            name="description"
                            placeholder="It feels amazing after today’s…"
                            value={formState.description}
                            onChange={(e) =>
                              setFormState({
                                ...formState,
                                description: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="flex flex-col mt-4 input-control">
                          <label className="hidden" htmlFor="checkInImage primary">
                            {String.CHALLENGE_FORM.INPUT_PHOTO}
                          </label>
                          <input
                            accept="image/*"
                            className="hidden"
                            id="checkInImage"
                            name="image"
                            ref={uploadInputRef}
                            type="file"
                            onChange={onUploadImage}
                          />
                          <Button
                            disabled={isFileUploading}
                            type="button"
                            label={
                              isFileUploading
                                ? String.CHALLENGE_FORM.UPLOADING
                                : String.CHALLENGE_FORM.INPUT_PHOTO
                            }
                            onClickHandler={() => uploadInputRef.current?.click()}
                          />
                          {!isFileUploading && <p className="fileName">{formState.imageName}</p>}
                          {isFileSizeExceeding && (
                            <label className="my-2 text-center text-[#f62743]">
                              Image size exceeding 5MB !
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="confirmation-input  pb-4 ">
                      <div className="confirmation-checkbox">
                        <Checkbox
                          value={isTaskDone}
                          label="Task Completed"
                          id="confirmation"
                          onChangeHandler={(e) => setIsTaskDone(e.target.checked)}
                        />
                      </div>
                      <Button
                        label={String.CHALLENGE_FORM.SUBMIT}
                        type="submit"
                        variant="SECONDARY"
                        disabled={!isTaskDone}
                      />
                    </div>
                  </form>

                  {showCheckGif && (
                    <div className="flex justify-center items-center h-80">
                      <img
                        alt=""
                        src="https://media.tenor.com/0AVbKGY_MxMAAAAM/check-mark-verified.gif"
                      />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
