import { createContext, useEffect, useState } from 'react';
import { EVENT_STATUS } from '../constant/config';
import useAuth from '../hooks/useAuth';
import { User } from '../models/User';
import { addYMLUser, fetchChallengesByUser, fetchUserById } from '../services/apis';
import { checkEventStatus } from '../util/common';

const initialUserState: User = {
  challenges: [],
  email: '',
  id: '',
  isLoggedIn: false,
  isRegistered: false,
  name: '',
  picture: '',
};

export const UserContext = createContext<{
  user: User;
  isPending: boolean;
  isYMLUser: boolean;
  setUserChallenges?: () => void;
}>({
  user: initialUserState,
  isPending: true,
  isYMLUser: true,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserProvider = ({ children }: any) => {
  const { auth, logOut, isLoading } = useAuth();

  const [user, setUser] = useState<User>(initialUserState);
  const [isPending, setIsPending] = useState<boolean>(true);
  const [isYMLUser, setIsYMLUser] = useState(true);

  const eventStatus = checkEventStatus();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsubscribe = auth.onAuthStateChanged(async (userRes: any) => {
      if (!userRes) {
        setUser(initialUserState);
        setIsPending(isLoading);
        return;
      }
      const { displayName, email, photoURL, uid } = userRes;
      const isYMLMail = email.includes('@ymedialabs.com');

      setIsYMLUser(isYMLMail);

      if (isYMLMail) {
        const challenges = await fetchChallengesByUser(uid);
        const existingUser = await fetchUserById(uid);

        setUser({
          challenges,
          email,
          id: uid,
          isLoggedIn: true,
          isRegistered: !!existingUser,
          name: displayName,
          picture: photoURL,
        });

        if (eventStatus === EVENT_STATUS.NOT_STARTED) await addYMLUser(email, displayName, uid);
      } else {
        await logOut();
      }
      setIsPending(isLoading);
    });

    return () => unsubscribe();
  }, []);

  const setUserChallenges = () => {
    const fetchAndSetChallenges = async () => {
      const challenges = await fetchChallengesByUser(user.id);
      setUser({
        ...user,
        challenges,
      });
    };
    fetchAndSetChallenges();
  };

  return (
    <UserContext.Provider value={{ user, isPending, isYMLUser, setUserChallenges }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
