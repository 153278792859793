import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { CHALLENGES_REGISTERED, CHECKINS, USERS } from '../constant/collections';
import { ChallengeCategory } from '../constant/data';
import { db } from '../firebase/Config';
import { ChallengesRegistered } from '../models/ChallengesRegistered';
import { CheckIn } from '../models/CheckIn';
import { LeaderboardData } from '../models/LeaderboardData';

const addYMLUser = async (email: string, displayName: string, uid: string) => {
  const existingUser = await fetchUserById(uid);
  const currentDate = new Date();

  await setDoc(
    doc(db, 'users', uid),
    {
      createdAt: existingUser?.createdAt ? existingUser.createdAt : currentDate,
      email,
      lastLogin: currentDate,
      name: displayName,
    },
    { merge: true }
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchUserById = async (id: string): Promise<any> => {
  const userRef = doc(db, USERS, id);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) return;

  return docSnap.data();
};

const addChallenges = async (
  uId: string,
  userName: string,
  userProfileImage: string,
  selectedChallenges: Array<Record<string, string>>
) => {
  const batch = writeBatch(db);

  selectedChallenges.forEach((challenge) => {
    const challengesRef = doc(collection(db, CHALLENGES_REGISTERED));
    batch.set(challengesRef, {
      checkInArray: [],
      cId: challenge.challengeId,
      timeStamp: new Date(),
      uId: uId,
      userName,
      userProfileImage,
    });
  });

  await batch.commit();
};

const fetchChallengeLeaderboardData = async (cId: string): Promise<Array<LeaderboardData>> => {
  const fetchedDocs: LeaderboardData[] = [];

  const challengesRef = collection(db, CHALLENGES_REGISTERED);
  const q = query(challengesRef, where('cId', '==', cId));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) return fetchedDocs;

  querySnapshot.forEach((doc) => {
    const record = doc.data() as ChallengesRegistered;

    const daysCompleted = record.checkInArray.filter((e) => e?.id).length;

    fetchedDocs.push({
      daysCompleted,
      id: cId,
      name: record.userName,
      profileImage: record.userProfileImage,
      rank: 1,
    });
  });

  return sortLeaderboardData(fetchedDocs);
};

const fetchMasterLeaderboardData = async (): Promise<Array<LeaderboardData>> => {
  const fetchedDocs: LeaderboardData[] = [];

  const challengesRef = collection(db, CHALLENGES_REGISTERED);

  const snapshot = await getDocs(challengesRef);

  if (snapshot.empty) return fetchedDocs;

  snapshot.forEach(async (doc) => {
    const record = doc.data() as ChallengesRegistered;

    const challengeName = ChallengeCategory.find(
      (challenge) => challenge.id === record.cId
    )?.shortName;

    const daysCompleted = record.checkInArray.filter((e) => e?.id).length;

    fetchedDocs.push({
      challengeName,
      daysCompleted,
      id: record.cId,
      name: record.userName,
      profileImage: record.userProfileImage,
      rank: 1,
    });
  });

  return sortLeaderboardData(fetchedDocs);
};

const fetchChallengesByUser = async (uId: string): Promise<Array<ChallengesRegistered>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchedDocs: any = [];

  const challengesRef = collection(db, CHALLENGES_REGISTERED);
  const q = query(challengesRef, where('uId', '==', uId));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    const challenge = ChallengeCategory.find((challenge) => challenge.id === doc.data().cId);

    fetchedDocs.push({
      ...doc.data(),
      registeredChallengeId: doc.id,
      challenge,
    });
  });

  return fetchedDocs;
};

const addDailyCheckIn = async (checkIn: CheckIn, index: number, registeredChallengeId: string) => {
  const { isCheat, duration, quantity } = checkIn;
  let formattedDuration = '0';
  const formattedQuantity = `${quantity && quantity > 0 ? quantity + ' glasses' : quantity}`;

  const challengesRef = doc(db, CHALLENGES_REGISTERED, registeredChallengeId);
  const snapshot = await getDoc(challengesRef);

  if (duration && duration > 60) {
    const { hours, minutes } = convertToHoursAndMinutes(duration);
    formattedDuration = `${hours} hrs ${minutes} mins`;
  } else if (duration !== 0) formattedDuration = `${duration} mins`;

  if (snapshot.exists()) {
    const updatedDoc = snapshot.data();

    // Save the data and update status array
    // in case of successful check in else
    // update the status array index value to 1
    // for marking it as cheat day
    if (isCheat) updatedDoc.checkInArray[index].id = '1';
    else {
      const docRef = await addDoc(collection(db, CHECKINS), {
        ...checkIn,
        duration: formattedDuration,
        quantity: formattedQuantity,
        timeStamp: new Date(),
      });

      /**
       * Issue:
       *  If user tries to enter details for Nth day in case (N-M)th day's details are not available
       *  then it throws firebase error
       *
       * Fix:
       *  Fill empty details for previous dates to null
       */
      let startIndex = 0;
      if (updatedDoc.checkInArray?.length) {
        startIndex = updatedDoc.checkInArray.length;
      }
      while (startIndex < index) {
        updatedDoc.checkInArray[startIndex] = null;
        startIndex++;
      }

      updatedDoc.checkInArray[index] = {
        id: docRef.id,
        duration: formattedDuration,
        quantity: formattedQuantity,
      };
    }

    await setDoc(
      challengesRef,
      {
        ...updatedDoc,
      },
      { merge: true }
    );
  } else console.error('No such document!');
};

const fetchCheckInDetailsById = async (checkInId: string): Promise<CheckIn> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let checkInRecord: any = {};

  const checkInRef = doc(db, CHECKINS, checkInId);
  const snapshot = await getDoc(checkInRef);

  if (snapshot.exists()) checkInRecord = snapshot.data();
  else console.error('No such document!');

  return checkInRecord;
};

// Fetch complete check in data.
const fetchAllCheckInDetails = async (): Promise<CheckIn[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchedDocs: any = [];

  const checkInRef = collection(db, CHECKINS);

  const snapshot = await getDocs(checkInRef);

  if (snapshot.empty) return fetchedDocs;

  snapshot.forEach((doc) => {
    fetchedDocs.push({
      ...doc.data(),
    });
  });

  return fetchedDocs;
};

const sortLeaderboardData = (data: Array<LeaderboardData>) => {
  data.sort((a, b) => {
    if (b.daysCompleted > a.daysCompleted) return 1;
    if (b.daysCompleted < a.daysCompleted) return -1;
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;

    return 0;
  });

  let rank = 1;
  let rankReference = data[0].daysCompleted;

  data.forEach((record) => {
    if (record.daysCompleted < rankReference) {
      rank++;
      rankReference = record.daysCompleted;
    }

    record.rank = rank;
  });

  return data;
};

const convertToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
};

export {
  addChallenges,
  addDailyCheckIn,
  addYMLUser,
  fetchChallengeLeaderboardData,
  fetchChallengesByUser,
  fetchCheckInDetailsById,
  fetchMasterLeaderboardData,
  fetchUserById,
  fetchAllCheckInDetails,
};
