import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/Config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const useAuth = () => {
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();

  const [isLoading, setLoading] = useState(false);

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const logOut = async () => {
    await auth.signOut();
    navigate('/', { replace: true });
  };

  return { auth, logOut, signInWithGoogle, isLoading };
};

export default useAuth;
