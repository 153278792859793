import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/zoom';

import './PhotoGallery.scss';

import { Autoplay, Pagination, Navigation, Zoom } from 'swiper';

import { fetchAllCheckInDetails } from '../../services/apis';
import { CheckIn } from '../../models/CheckIn';
import { getDateString } from '../../util/photoGallery';
import { ChallengesRegistered } from '../../models/ChallengesRegistered';
import { String } from '../../constant/string';

interface CheckInInfo extends CheckIn {
  timeStamp?: Record<string, number>;
}
interface Props {
  dayIndex: number;
  selectedChallenges: ChallengesRegistered[];
  heading?: string;
}

export const PhotoGallery = ({
  dayIndex,
  selectedChallenges,
  heading = String.IMAGE_GALLERY_HEADER,
}: Props) => {
  const [checkIn, setcheckIn] = useState<CheckInInfo[]>([]);

  const checkIfImageExists = () => checkIn.find((checkIn: CheckIn) => checkIn.imageUrl);

  const fetchAllCheckInData = async () => {
    const checkIns = await fetchAllCheckInDetails();
    const currentCheckInInfo: CheckInInfo[] = checkIns.filter(
      (checkIn: CheckInInfo) =>
        checkIn &&
        checkIn.timeStamp &&
        +getDateString(checkIn?.timeStamp.seconds).split(' ')[2] === dayIndex + 1
    );

    setcheckIn(currentCheckInInfo);
  };

  useEffect(() => {
    fetchAllCheckInData();
  }, [dayIndex, selectedChallenges]);

  return (
    <>
      {checkIfImageExists() && (
        <>
          <h1 className="Image-gallery-title px-2">{heading}</h1>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            zoom
            navigation
            modules={[Autoplay, Pagination, Navigation, Zoom]}
            className="mySwiper">
            {checkIn &&
              checkIn.length &&
              checkIn.map(
                (eachCheckIn: CheckInInfo) =>
                  eachCheckIn.imageUrl && (
                    <SwiperSlide
                      key={`${eachCheckIn?.timeStamp?.seconds}${
                        eachCheckIn?.timeStamp?.nanoseconds
                      }${Math.random()}`}>
                      <div className="swiper-zoom-container">
                        <img
                          className="w-full h-96"
                          src={eachCheckIn.imageUrl}
                          alt="challenge detail"
                        />
                      </div>
                    </SwiperSlide>
                  )
              )}
          </Swiper>
        </>
      )}
    </>
  );
};
