const RegistrationsClosed = () => (
  <>
    <h1 className="flex items-center justify-center h-full text-3xl px-6 text-center">
      Registration closed !!
      <br />
      Thanks for showing interest.
    </h1>
  </>
);

export default RegistrationsClosed;
