import { useState, useContext } from 'react';
import './ChallengesForm.scss';
import { UserContext } from '../../providers/UserProvider';
import { addChallenges } from '../../services/apis';
import { ChallengeSelection } from '../ChallengeSelection/ChallengeSelection';
import { DashboardHeader } from '../../component/dashboard/dashboard-header/DashboardHeader';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { String } from '../../constant/string';
import { Checkbox } from '../Checkbox/Checkbox';
import { Button } from '../base/button/Button';
import { TChallengeCategory } from '../../constant/data';

export const ChallengesForm = () => {
  const [selectedChallenges, setSelectedChallenges] = useState<Array<TChallengeCategory>>([]);
  const [isChallengeAccepted, setIsChallengeAccepted] = useState(false);

  const { user, setUserChallenges } = useContext(UserContext);
  const { id, name, picture } = user;

  const onSubmitHandler = async () => {
    const selectedChallengeObj = selectedChallenges.map((challenge) => {
      return {
        challengeId: challenge.id,
        challengeIcon: challenge.icon,
      };
    });

    await addChallenges(id, name, picture, selectedChallengeObj);
    setUserChallenges?.();
  };

  const challengeSelectionHandler = (challenge: Array<TChallengeCategory>) => {
    setSelectedChallenges(challenge);
  };

  const acceptChallengeHandler: React.ChangeEventHandler<HTMLInputElement> | undefined = (e) =>
    setIsChallengeAccepted(e.target.checked);
  return (
    <>
      <div className="ChallengesForm">
        <DashboardHeader />
        <div className="content-wrapper">
          <LazyMotion features={domAnimation}>
            <m.h3 className="challenge-selection-heading text-center uppercase mt-4 mb-4">
              <span>The best thing you could ever do is</span>
              <span>Believe in Yourself</span>
            </m.h3>
          </LazyMotion>
          {/* <p className="quote2">Stop Wishing: Start Doing</p> */}
          <div className="challenge-content">
            <p className="challenge-selection-condition">
              Please select minimum 1 to maximum 3 challenges.
            </p>
            <ChallengeSelection callback={challengeSelectionHandler}></ChallengeSelection>
            <p className="selected-challenges">
              {selectedChallenges.map((challenge) => (
                <LazyMotion features={domAnimation} key={challenge.id}>
                  <m.span initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }}>
                    {challenge.shortName}
                  </m.span>
                </LazyMotion>
              ))}
            </p>
          </div>
          <div className="submit-cta-wrapper">
            {!!selectedChallenges.length && (
              <div className="submit-wrapper">
                <LazyMotion features={domAnimation}>
                  <m.div initial={{ x: -1000 }} animate={{ x: 0 }} className="get-started-text">
                    <Checkbox
                      id="getStarted"
                      value={isChallengeAccepted}
                      label={String.CHALLENGE_SELECTION.AGREEMENT}
                      onChangeHandler={acceptChallengeHandler}
                      bgType="light"
                    />
                  </m.div>
                </LazyMotion>
                <p className="no-going-back-txt">* Remember there is no going back :)</p>
                <Button
                  disabled={!isChallengeAccepted}
                  label={String.CHALLENGE_SELECTION.SUBMIT}
                  type="button"
                  onClickHandler={onSubmitHandler}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
