import { useState } from 'react';
import CountdownTimer from "react-countdown";
import { START_DATE } from "../../constant/config";

type TTime = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}
const initialTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
}
export const CountDown = () => {
    const [time, setTime] = useState(initialTime);

    const onTimerCompleteHandler = () => {
        location.reload();
    }

    const OnTickHandler = (data: TTime) => {
        setTime(data);
    }

    const Timer = new Date(START_DATE);
    const { days, hours, minutes, seconds } = time;

    return (
        <>
            <CountdownTimer onTick={OnTickHandler} onComplete={onTimerCompleteHandler} date={Timer} className="counter">
                <></>
            </CountdownTimer>

            <div className='custom-counter'>
                <div className='time-wrapper'>
                    <span className='time'>{days}</span>
                    <span className='time-label'>{days > 1 ? 'Days' : 'Day'}</span>
                </div>
                <div className='time-wrapper'>
                    <span className='time'>{hours}</span>
                    <span className='time-label'>{hours > 1 ? 'Hours' : 'Hour'}</span>
                </div>
                <div className='time-wrapper'>
                    <span className='time'>{minutes}</span>
                    <span className='time-label'>{minutes > 1 ? 'Minutes' : 'Minute'}</span>
                </div>
                <div className='time-wrapper'>
                    <span className='time'>{seconds}</span>
                    <span className='time-label'>Seconds</span>
                </div>
            </div>
        </>
    )
}