import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../providers/UserProvider';
import { useContext } from 'react';
import { Loader } from '../component/loader/loader';

const ProtectedRoutes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const { isPending } = useContext(UserContext);

  if (isPending) {
    return <Loader />;
  }

  return isLoggedIn ? <Outlet /> : <Navigate replace to="/" />;
};

export default ProtectedRoutes;
